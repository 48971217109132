// 
// user.js
// Use this to write your custom JS

{
    const match = window.location.pathname.match(/^\/(en|it)/);
    const lang = match ? match[1] : 'en';
    document.querySelector('.bb-current-lang').textContent = lang.toUpperCase();
    document.querySelector('.bb-current-lang-menu .dropdown-item[data-lang="' + lang + '"]').classList.add('active');
}

document.querySelectorAll('.bb-current-lang-menu .dropdown-item').forEach(link => {
    link.addEventListener('click', function (e) {
        localStorage.setItem('preferredLanguage', this.dataset.lang);
    });
});

if (window.location.pathname == '/') {
    var userLang = localStorage.getItem('preferredLanguage');
    if (!userLang) {
        userLang = navigator.language || navigator.userLanguage;
        userLang = userLang.split('-')[0];  // en-US -> en
    }
    if (userLang != 'en') {
        window.location.href = '/' + userLang + '/';
    }
}